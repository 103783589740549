export enum SITECORE_AUTHORIZATION {
	CLIENT_ID = 'MPUI',
	DECISION = 'allow',
	RESPONSE_TYPE = 'code',
	SCOPE = 'member.rw',
	STATE = 'abc123',
}

export enum SITECORE_PAGES {
	DASHBOARD = '/',
	LOGIN = '/login',
	DEVICE_CHECK = '/device-check',
	LOGOUT = '/personal/logout',
	REGISTRATION = '/portal-registration',
	FORGOT_PASSWORD = '/forgot-password',
	MESSAGES = '/messages',
	MESSAGE = '/messages/message',
	ACCOUNT = '/account',
	WHO_GETS_YOUR_MONEY = '/account/who-gets-your-money',
	WHO_GETS_YOUR_BENEFIT = '/account/who-gets-your-benefit',
	PRODUCT_GUIDES = '/account/product-guides',
	TRANSACTIONS = '/transactions',
	CONTRIBUTIONS = '/transactions/contributions',
	CONSOLIDATE_SUPER = '/transactions/consolidate-super',
	TAX_FILE_NUMBER = '/account/tax-file-number',
	MESSAGES_FROM_HEADER = '/messages/message',
	COMBINE_SUPER_MATCH = '/transactions/consolidate-super/combine-super',
	NOTIFY_EMPLOYER_CONFIRMATION = '/notify-employer-confirmation',
	UNAUTHORIZED = '/unauthorized',
	SOMETHING_WENT_WRONG = '/something-went-wrong',
	SCHEDULED_MAINTENANCE = '/scheduled-maintenance',
	WITHDRAWAL_LANDING_PAGE = '/transactions/make-a-withdrawal',
	REACHED_PRESERVATION_AGE = '/transactions/make-a-withdrawal/reached-preservation-age',
	ACCESS_UNRESTRICTED_MONEY = '/transactions/make-a-withdrawal/access-unrestricted-money',
	SMSF = '/transactions/make-a-withdrawal/transfer-to-another-super-fund',
	PENSION = '/transactions/make-a-withdrawal/pension',
	FINANCIAL_HARDSHIP_A = '/transactions/make-a-withdrawal/financial-hardship',
	FINANCIAL_HARDSHIP_B = '/transactions/make-a-withdrawal/financial-hardship-reached-preservation-age',
	INVESTMENTS = '/investments',
	INSURANCE = '/insurance',
	NOI_LANDING_PAGE = '/transactions/claim-a-tax-deduction',
	NOI_FORM = '/transactions/claim-a-tax-deduction/notice-of-intent',
	DIRECT_DEBIT_LANDING_PAGE = '/transactions/contributions/direct-debit',
	DIRECT_DEBIT_FORM = '/transactions/contributions/direct-debit/direct-debit-form',
	CHANGE_BANK_DETAILS_FORM = '/account/bank-details/change-bank-details',
	SPOUSE_CONTRIBUTIONS_CONFIRMATION = '/transactions/contributions/spouse-contributions/spouse-contributions-confirmation',
	ACCOUNT_LOCKED_SCREEN = '/account/account-locked-screen',
	RESILIENCE_MAINTENANCE_MODE = '/resilience-maintenance',
	CONTENT_NOT_AVAILABLE = '/content-not-available',
}
